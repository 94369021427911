export default {
  methods: {
    canMultiply(betType) {
      if (
        betType == 3 ||
        betType == 11 ||
        betType == 12 ||
        betType == 23 ||
        betType == 24 ||
        betType == 2 ||
        betType == 1 ||
        betType == 18
      ) {
        return true;
      }
      return false;
    },
    canSwap(betType) {
      if (betType == 1 || betType == 18 || betType == 5) {
        return true;
      }
      return false;
    },
    isDouble(betNumber) {
      if (betNumber.length != 2) return false;
      const N = betNumber.split("");
      if (N[0] == N[1]) return true;
      return false;
    },
    isTripple(betNumber) {
      if (betNumber.length != 3) return false;
      const N = betNumber.split("");
      if (N[0] == N[1] && N[0] == N[2]) return true;
      return false;
    },
    getBetTypeName(betType) {
      switch (betType) {
        case "1":
          return "บน";
        case "2":
          return "ล่าง";
        case "3":
          return "บ+ล";
        case "4":
          return "พวง";
        case "5":
          return "3ล่าง";
        case "6":
          return "บน/น";
        case "7":
          return "บน/ก";
        case "8":
          return "บน/ท";
        case "9":
          return "ล่าง/น";
        case "10":
          return "ล่าง/ท";
        case "11":
          return "บน/น";
        case "12":
          return "บน/ถ";
        case "13":
          return "ล.บ.";
        case "14":
          return "ล.ล";
        case "15":
          return "19/บ";
        case "16":
          return "19/ล";
        case "17":
          return "ค.ต.";
        case "18":
          return "3น.";
        case "19":
          return "3น.";
        case "20":
          return "3น/น";
        case "21":
          return "3น/ก";
        case "22":
          return "3น/ท";
        case "23":
          return "3น/น";
        case "24":
          return "3น/ถ";
        case "25":
          return "3น.";
        case "26":
          return "ต/3น.";
      }
    },
    getBetTypeDetailName(betTypeDetail) {
      switch (betTypeDetail) {
        case 1:
          return "3บน";
        case 2:
          return "3โต๊ด";
        case 3:
          return "2บน";
        case 4:
          return "2ล่าง";
        case 5:
          return "ค.ต.";
        case 6:
          return "บ+ล";
        case 7:
          return "พวง";
        case 8:
          return "หมุน";
        case 9:
          return "จ.บ.น.";
        case 10:
          return "จ.บ.ก.";
        case 11:
          return "จ.บ.ท.";
        case 12:
          return "จ.ล.น.";
        case 13:
          return "จ.ล.ท.";
        case 14:
          return "ล.บ.";
        case 15:
          return "ล.ล.";
        case 16:
          return "คู่หน้า";
        case 17:
          return "คู่ถ่าง";
        case 18:
          return "3บน/หน้า";
        case 19:
          return "3โต๊ด/หน้า";
        case 20:
          return "2บน/หน้า";
        case 21:
          return "จมบนหน้า/หน้า";
        case 22:
          return "จมบนกลาง/หน้า";
        case 23:
          return "จมบนห้าย/หน้า";
        case 24:
          return "ลอยบน/หน้า";
        case 25:
          return "คู่หน้า/หน้า";
        case 26:
          return "คู่ถ่าง/หน้า";
        case 27:
          return "พวง/หน้า";
        case 28:
          return "คู่โต๊ด/หน้า";
      }
    },
    getBetTypeDetailNameFull(betTypeDetail) {
        switch (betTypeDetail) {
          case 1:
            return "3 ตัวบน";
          case 2:
            return "3 ตัวโต๊ด";
          case 3:
            return "2 ตัวบน";
          case 4:
            return "2 ตัวล่าง";
          case 5:
            return "คู่โต็ด";
          case 6:
            return "บน+ล่าง";
          case 7:
            return "พวง";
          case 8:
            return "หมุน";
          case 9:
            return "จมบนหน้า";
          case 10:
            return "จมบนกลาง";
          case 11:
            return "จมบนท้าย";
          case 12:
            return "จมล่างหน้า";
          case 13:
            return "จมล่างท้าย";
          case 14:
            return "ลอยบน";
          case 15:
            return "ลอยล่าง";
          case 16:
            return "คู่หน้า";
          case 17:
            return "คู่ถ่าง";
          case 18:
            return "3บน/หน้า";
          case 19:
            return "3โต๊ด/หน้า";
          case 20:
            return "2บน/หน้า";
          case 21:
            return "จมบนหน้า/หน้า";
          case 22:
            return "จมบนกลาง/หน้า";
          case 23:
            return "จมบนห้าย/หน้า";
          case 24:
            return "ลอยบน/หน้า";
          case 25:
            return "คู่หน้า/หน้า";
          case 26:
            return "คู่ถ่าง/หน้า";
          case 27:
            return "พวง/หน้า";
          case 28:
            return "คู่โต๊ด/หน้า";
        }
      },
    getMaxBetTypeLength(betType) {
      if (
        betType == "6" ||
        betType == "7" ||
        betType == "8" ||
        betType == "9" ||
        betType == "10" ||
        betType == "13" ||
        betType == "14" ||
        betType == "15" ||
        betType == "16" ||
        betType == "20" ||
        betType == "21" ||
        betType == "22" ||
        betType == "25"
      ) {
        //
        return 1;
      } else if (
        betType == "2" ||
        betType == "3" ||
        betType == "11" ||
        betType == "12" ||
        betType == "17" ||
        betType == "23" ||
        betType == "24" ||
        betType == "26"
      ) {
        //
        return 2;
      } else if (betType == "1" || betType == "5" || betType == "18") {
        //บน บนหน้า หมุน
        return 3;
      } else if (betType == "4" || betType == "19") {
        //พวง พวงหน้า
        return 5;
      } else {
        return 0;
      }
    },
    getMinBetTypeLength(betType) {
      if (
        betType == "6" ||
        betType == "7" ||
        betType == "8" ||
        betType == "9" ||
        betType == "10" ||
        betType == "13" ||
        betType == "14" ||
        betType == "15" ||
        betType == "16" ||
        betType == "20" ||
        betType == "21" ||
        betType == "22" ||
        betType == "25"
      ) {
        //
        return 1;
      } else if (
        betType == "2" ||
        betType == "3" ||
        betType == "11" ||
        betType == "12" ||
        betType == "17" ||
        betType == "23" ||
        betType == "24" ||
        betType == "26" ||
        betType == "1" ||
        betType == "18"
      ) {
        //ล่าง
        return 2;
      } else if (betType == "5") {
        //บน หมุน บนหน้า
        return 3;
      } else if (betType == "4" || betType == "19") {
        //พวง พวงหน้า
        return 5;
      } else {
        return 0;
      }
    },
  },
};
