<template>
  <div>
    <v-app-bar dense fixed app color="blue darken-4" dark>
      <v-btn to="/menu_data" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>แก้ไขโพยส่งออก</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-file-find</v-icon>
      </v-btn>
    </v-app-bar>
    <v-breadcrumbs :items="bc_items" class="pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-toolbar flat color="grey lighten-5">
      <v-btn rounded id="menu-activator" color="info" dark>
        <v-icon left>
          mdi-chevron-down
        </v-icon>
        จัดการ
      </v-btn>

      <v-menu activator="#menu-activator" offset-y>
        <v-list>
          <!-- <AddPage
            :BookieID="BookieID"
            @Reload="fetch_table"
            @EnterPage="EnterPage"
          /> -->
          <!-- <v-list-item @click="MergePage" style="background-color: Beige;">
            <v-list-item-title style="color: black;">
              <v-icon left>
                mdi-set-merge
              </v-icon>
              รวมโพย</v-list-item-title
            >
          </v-list-item> -->
          <v-list-item style="background-color: LightCyan;">
            <v-list-item-title style="color: black;">
              <v-icon left>
                mdi-printer
              </v-icon>
              พิมพ์</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        class="ml-1"
        rounded
        outlined
        id="menu-activator"
        color="info"
        dark
      >
        <v-icon style="color: lightblue ;">
          mdi-bookmark
        </v-icon>
      </v-btn>
      <v-divider class="mx-4 info" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn rounded outlined color="red">
        <v-icon left>
          mdi-sort
        </v-icon>
        เรียงเวลา
      </v-btn>
    </v-toolbar>

    <div class=" mt-5" style="margin: auto;" ref="PrintMe">
      <v-toolbar
        class="mb-2"
        dense
        style="color:darkslateblue; background-color:whitesmoke;font-weight: bold;"
      >
        {{ getAbbrFullMonthPeriodThai(PeriodDT) }}
        <v-spacer></v-spacer>
        ถิง
      </v-toolbar>

      <v-simple-table
        v-for="item in pages_data"
        :key="item.BetTypeDetailID"
        style="border-bottom: 1px solid lightgray;"
      >
        <template v-slot:default>
          <thead>
            <tr style="background-color:mintcream;">
              <th class="text-center indigo--text" colspan="2">
                {{ getBetTypeText(item.BetTypeDetailID) }}
              </th>
              <th
                class="text-center"
                colspan="2"
                style="background-color: whitesmoke;"
              >
                <v-text-field
                  label="เลข"
                  type="number"
                  ref="txtNum"
                  v-model="Input[parseInt(item.BetTypeDetailID) - 1].Num"
                  @keypress="OnNumKey($event, item.BetTypeDetailID)"
                  @keyup.enter="OnNumEnter(item.BetTypeDetailID)"
                  @keydown="OnNumKeyDown($event, item.BetTypeDetailID)"
                  OnNumKeyDown
                  hide-details
                  dense
                  required
                ></v-text-field>
              </th>
              <th
                class="text-center"
                colspan="2"
                style="background-color:whitesmoke;"
              >
                <v-text-field
                  label="เงิน"
                  type="number"
                  ref="txtPrice"
                  v-model="Input[parseInt(item.BetTypeDetailID) - 1].Price"
                  @focus="onPriceFocus(item.BetTypeDetailID)"
                  @keyup.enter="OnPriceEnter(item.BetTypeDetailID)"
                  @keypress="OnPriceKey($event, item.BetTypeDetailID)"
                  @keydown.delete="OnPriceDel($event, item.BetTypeDetailID)"
                  @keydown="OnPriceKeyDown($event)"
                  hide-details
                  dense
                  required
                ></v-text-field>
              </th>
              <th
                class="text-center"
                colspan="4"
                style="background-color: white;"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="index in getRowCount(item.BetTypeDetailID)" :key="index">
              <td
                width="5%"
                style="font-weight: bold;border-left: 1px solid;border-color: lightgray;"
                @click="Edit(item.BetTypeDetailID, index, 1)"
              >
                {{ getNumber(item.BetTypeDetailID, index, 1) }}
              </td>
              <td
                width="15%"
                style="font-weight: bold;border-right: 1px solid;border-color: lightgray;"
                @click="Edit(item.BetTypeDetailID, index, 1)"
              >
                {{ getPrice(item.BetTypeDetailID, index, 1) }}
              </td>
              <td width="5%" @click="Edit(item.BetTypeDetailID, index, 2)">
                {{ getNumber(item.BetTypeDetailID, index, 2) }}
              </td>
              <td
                width="15%"
                style="font-weight: bold;border-right: 1px solid;border-color: lightgray;"
                @click="Edit(item.BetTypeDetailID, index, 2)"
              >
                {{ getPrice(item.BetTypeDetailID, index, 2) }}
              </td>
              <td width="5%" @click="Edit(item.BetTypeDetailID, index, 3)">
                {{ getNumber(item.BetTypeDetailID, index, 3) }}
              </td>
              <td
                width="15%"
                style="font-weight: bold;border-right: 1px solid;border-color: lightgray;"
                @click="Edit(item.BetTypeDetailID, index, 3)"
              >
                {{ getPrice(item.BetTypeDetailID, index, 3) }}
              </td>
              <td width="5%" @click="Edit(item.BetTypeDetailID, index, 4)">
                {{ getNumber(item.BetTypeDetailID, index, 4) }}
              </td>
              <td
                width="15%"
                style="font-weight: bold;border-right: 1px solid;border-color: lightgray;"
                @click="Edit(item.BetTypeDetailID, index, 4)"
              >
                {{ getPrice(item.BetTypeDetailID, index, 4) }}
              </td>
              <td width="5%" @click="Edit(item.BetTypeDetailID, index, 5)">
                {{ getNumber(item.BetTypeDetailID, index, 5) }}
              </td>
              <td
                width="15%"
                style="font-weight: bold; border-right: 1px solid;border-color: lightgray;"
                @click="Edit(item.BetTypeDetailID, index, 5)"
              >
                {{ getPrice(item.BetTypeDetailID, index, 5) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="text-right mt-2 mr-3">
        {{ this.BookieName }}
      </div>
    </div>

    <!-- <div class="text-center mt-5">
      <v-btn color="indigo" rounded dark @click="print()">
        <v-icon class="mr-2">mdi-image-size-select-actual</v-icon>
        บันทึกรูปภาพ
      </v-btn>
    </div> -->
    <v-dialog
      v-model="edit_dialog"
      :hide-overlay="$vuetify.breakpoint.mdAndDown"
      max-width="450px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="red justify-center">
          <span class="text-h5 white--text">แก้ไข</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="3">
                <span class="text-h6 red--text">{{
                  getBetTypeText(EditOldBetType)
                }}</span>
              </v-col>
              <v-col cols="4">
                <span class="text-h6 red--text">{{ EditOldNumber }}</span>
              </v-col>
              <v-col cols="1">
                <span class="text-h6 red--text"> = </span>
              </v-col>
              <v-col cols="4">
                <span class="text-h6 red--text">{{ EditOldPrice }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-divider class="mt-2 mb-2"></v-divider>
            </v-row>

            <v-row>
              <v-col cols="3" class="d-flex align-end">
                <span class="black--text">{{
                  getBetTypeText(EditOldBetType)
                }}</span>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="เลข"
                  type="number"
                  ref="txtEditNum"
                  v-model="EditNewNumber"
                  @keypress="OnNumKey($event, EditOldBetType)"
                  @keyup.enter="OnNumEnter(EditOldBetType)"
                  @keydown="OnNumKeyDown($event, EditOldBetType)"
                  OnNumKeyDown
                  hide-details
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <span class="text-h6 black--text"> = </span>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="เลข"
                  type="number"
                  ref="txtEditNum"
                  v-model="EditNewNumber"
                  @keypress="OnNumKey($event, EditOldBetType)"
                  @keyup.enter="OnNumEnter(EditOldBetType)"
                  @keydown="OnNumKeyDown($event, EditOldBetType)"
                  OnNumKeyDown
                  hide-details
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
            <v-btn color="red darken-1" text>
            ลบ
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="edit_dialog = false">
            ยกเลิก
          </v-btn>
          <v-btn color="info darken-1" text @click="Create()">
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import betFunctionMixin from "@/mixins/betFunctionMixin";

export default {
  name: "Export_Page_Image",
  mixins: [globalFunctionMixin, betFunctionMixin],
  components: {},

  mounted() {
    this.ckLogin();

    this.bc_items[0].text = "โพยตีออก";
    this.bc_items[0].to = { path: "export_page" };

    this.bc_items[1].text =
      this.$route.query.bookie_id +
      " ( " +
      this.$store.getters["bookie/BookiesDetail"]["Name"];
    +" )";

    this.bc_items[1].to = {
      path: "export_page_list",
      query: {
        bookie_id: this.$route.query.bookie_id,
      },
    };

    this.bc_items[2].text = this.$route.query.page_number;
  },
  created() {
    //โหลด ตาราง
    this.BookieID = this.$route.query.bookie_id;
    this.PageNumber = this.$route.query.page_number;
    this.PeriodDT = sessionStorage.getItem("period");
    this.fetch_bookie_table();
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    pages_data() {
      return this.$store.getters["export_page/PageData"];
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        PageNumber: this.PageNumber,
      };
      await this.$store
        .dispatch("export_page/get_page_data_edit", credentials)
        .then(
          (response) => {
            //   console.log(this.$store.getters["agent/AgentDetail"][0].Nickname);
            //   this.bc_items[1].text =
            //     this.$route.query.bookie_id + " ( " + response[0].Name + " )";
            //   this.BookieName = response[0].Name;
            //   this.$store.getters["pages/Pages"];
          },
          (error) => {
            console.log(error);
          }
        );
    },
    async fetch_bookie_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        ListType: "get_detail",
      };
      await this.$store.dispatch("bookie/get_detail", credentials).then(
        (response) => {
          //   console.log(this.$store.getters["agent/AgentDetail"][0].Nickname);
          this.bc_items[1].text =
            this.$route.query.bookie_id + " ( " + response[0].Name + " )";
          this.BookieName = response[0].Name;
          //   this.$store.getters["pages/Pages"];
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getRowCount(BetTypeDetailID) {
      for (
        let i = 0;
        i < this.$store.getters["export_page/PageData"].length;
        i++
      ) {
        if (
          this.$store.getters["export_page/PageData"][i]["BetTypeDetailID"] ==
          BetTypeDetailID
        ) {
          let PageData = this.$store.getters["export_page/PageData"][i];
          var length = PageData["Number"].length;
          return Math.floor((length - 1) / 5) + 1;
        }
      }
      return 0;
    },
    getNumber(BetTypeDetailID, Row, Column) {
      let PageDatas = this.$store.getters["export_page/PageData"];
      for (let i = 0; i < PageDatas.length; i++) {
        if (
          this.$store.getters["export_page/PageData"][i]["BetTypeDetailID"] ==
          BetTypeDetailID
        ) {
          let Number = PageDatas[i]["Number"][(Row - 1) * 5 + (Column - 1)];
          if (Number == null) return "";
          return Number;
        }
      }
      return "";
    },
    getPrice(BetTypeDetailID, Row, Column) {
      let PageDatas = this.$store.getters["export_page/PageData"];
      for (let i = 0; i < PageDatas.length; i++) {
        if (
          this.$store.getters["export_page/PageData"][i]["BetTypeDetailID"] ==
          BetTypeDetailID
        ) {
          let Price = PageDatas[i]["Price"][(Row - 1) * 5 + (Column - 1)];
          if (Price == null) return "";
          return " = " + Price;
        }
      }
      return "";
    },
    Edit(BetTypeDetailID, Row, Column) {
      let PageDatas = this.$store.getters["export_page/PageData"];
      for (let i = 0; i < PageDatas.length; i++) {
        if (
          this.$store.getters["export_page/PageData"][i]["BetTypeDetailID"] ==
          BetTypeDetailID
        ) {
          let Number = PageDatas[i]["Number"][(Row - 1) * 5 + (Column - 1)];
          if (Number == null) return "";
          let Price = PageDatas[i]["Price"][(Row - 1) * 5 + (Column - 1)];
          if (Price == null) return "";
          //popup number price
          this.EditOldBetType = BetTypeDetailID;
          this.EditOldNumber = Number;
          this.EditOldPrice = Price;
          this.EditNewNumber = Number;
          this.EditNewPrice = Price;
          this.edit_dialog = true;
          console.log(Number + "=" + Price);
        }
      }
    },
    getBetTypeText(betType) {
      const statusText = [
        "3 บน",
        "3 โต๊ด",
        "2 บน",
        "2 ล่าง",
        "คู่โต๊ด",
        "",
        "พวง",
        "",
        "จม บ.น.",
        "จม บ.ก.",
        "จม บ.ท.",
        "จม. ล.น.",
        "จม. ล.ท.",
        "ลอยบน",
        "ลอยล่าง",
        "คู่หน้า",
        "คู่ถ่าง",
      ];
      return statusText[parseInt(betType - 1)];
    },
    OnNumKey(evt, betType) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 43 || charCode == 45 || charCode == 46)
        evt.preventDefault();
      var arr_bt = [3, 3, 2, 2];
      var b_len = arr_bt[parseInt(betType) - 1];
      if (this.Input[parseInt(betType) - 1].Num.length >= b_len)
        evt.preventDefault();
      return true;
    },
    OnPriceKey(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 43 || charCode == 45 || charCode == 46)
        evt.preventDefault();
    },
    OnNumEnter(betType) {
      this.$refs.txtPrice[parseInt(betType) - 1].focus();
    },
    OnPriceEnter(betType) {
      if (this.Sending == true) return;
      this.Sending = true;
      // empty price text
      if (
        this.Input[parseInt(betType) - 1].Price.length == 0 &&
        this.LastPrice != 0
      ) {
        this.Input[parseInt(betType) - 1].Price = this.LastPrice;
        this.Sending = false;
        return;
      }

      if (this.Input[parseInt(betType) - 1].Price.length == 0) {
        this.ShowError("ราคาเป็นค่าว่างไม่ได้");
        this.Sending = false;
        return;
      }
      if (this.Input[parseInt(betType) - 1].Price == 0) {
        this.ShowError("ใส่เงินด้วย");
        this.Sending = false;
        return;
      }
      var arr_bt = [3, 3, 2, 2];
      var b_len = arr_bt[parseInt(betType) - 1];
      // Number incorrect

      if (this.Input[parseInt(betType) - 1].Num.length != b_len) {
        this.$refs.txtNum[parseInt(betType) - 1].focus();
        this.Sending = false;
        return;
      }
      this.OnSendLine(
        betType,
        this.Input[parseInt(betType) - 1].Num,
        this.Input[parseInt(betType) - 1].Price
      );
    },
    async OnSendLine(BetType, Num, Price) {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        PageNumber: this.PageNumber,
        BetType: BetType,
        Number: Num,
        Price: Price,
      };
      await this.$store.dispatch("export_page/add_line", credentials).then(
        (response) => {
          this.LastPrice = this.Input[parseInt(BetType) - 1].Price;
          this.Input[parseInt(BetType) - 1].Num = "";
          this.Input[parseInt(BetType) - 1].Price = "";
          this.$refs.txtNum[parseInt(BetType) - 1].focus();
        },
        (error) => {
          console.log(error);
        }
      );
      this.Sending = false;
    },
    onPriceFocus(betType) {
      var arr_bt = [3, 3, 2, 2];
      var bt = arr_bt[parseInt(betType) - 1];

      if (this.Input[parseInt(betType) - 1].Num.length < bt) {
        this.$refs.txtNum[parseInt(betType) - 1].focus();
      } else if (this.Input[parseInt(betType) - 1].Num.length > bt) {
        this.Input[parseInt(betType) - 1].Num = "";
        this.$refs.txtNum[parseInt(betType) - 1].focus();
      }
    },
    OnPriceKeyDown(evt) {
      if (evt.key == "ArrowDown" || evt.key == "ArrowUp") evt.preventDefault();
    },
    OnNumKeyDown(evt) {
      if (evt.key == "ArrowDown" || evt.key == "ArrowUp") evt.preventDefault();
    },
    OnPriceDel(e, betType) {
      if (e.key == "Backspace") {
        if (this.Input[parseInt(betType) - 1].Price == "") {
          this.$refs.txtNum[parseInt(betType) - 1].focus();
          e.preventDefault();
        }
      }
    },
    async print() {
      console.log("printing..");

      //   this.$refs.PrintMe.style.textAlign = "left"
      let el = this.$refs.PrintMe;
      //   el.style.textAlign  = "justify"
      const options = {
        type: "dataURL",
        backgroundColor: "#fff",
        width: 800,
        windowWidth: 800,
        left: 50,
      };
      const printCanvas = await this.$html2canvas(el, options);

      if (options.type && options.type === "dataURL") {
        // console.log(printCanvas);
        // this.output = printCanvas;
        const link = document.createElement("a");
        link.setAttribute(
          "download",
          "EXPORT-" + this.BookieName + "-" + this.PageNumber + ".png"
        );
        link.setAttribute("href", printCanvas);
        link.click();
      } else {
        console.warn(
          "Vue Html2Canvas Warn: Invalid option type. Use 'dataURL' instead. Returning canvas."
        );
        //return printCanvas;
      }

      console.log("done");
    },
    ShowError(ErrorText) {
      this.$alert(ErrorText, "ผิดพลาด", "error").then(() => {});
    },
  },
  data: () => ({
    BookieID: null,
    PageNumber: null,
    BookieName: null,
    PeriodDT: "",
    Sending: false,
    LastPrice: 0,
    edit_dialog: false,
    EditOldBetType: "",
    EditOldNumber: "",
    EditOldPrice: "",
    EditNewNumber: "",
    EditNewPrice: "",
    Input: [
      {
        Num: "",
        Price: "",
      },
      {
        Num: "",
        Price: "",
      },
      {
        Num: "",
        Price: "",
      },
      {
        Num: "",
        Price: "",
      },
    ],
    bc_items: [
      {
        text: "a1 ถิง",
        disabled: false,
      },
      {
        text: "Link 2",
        disabled: false,
      },
      {
        text: "Link 2",
        disabled: false,
      },
    ],
  }),
};
</script>

<style></style>
